<template>
    <b-overlay :show="is_busy" rounded="sm">
        <div class="container-fluid">
            <b-row class="mb-2">
                <b-col md="6">
                    <h2 v-show="!editMode"><strong>Enroll Staff</strong></h2>
                    <h2 v-show="editMode"><strong>Edit Staff</strong></h2>
                </b-col>
            </b-row>

            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="editMode ? updateStaff() : createStaff()">
                        <h2 class="text-center">Personal Data</h2>
                        <b-row>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Surname</label>
                                    <input v-model="form.lastname" type="text" class="form-control" required>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Firstname</label>
                                    <input v-model="form.firstname" type="text" class="form-control" required>
                                </div>
                            </div>
                        
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Middlename</label>
                                    <input v-model="form.middlename" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <b-form-group class="">
                                <label>Date of Birth</label>
                                <input v-model="form.dob" type="date" class="form-control">
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                <label>Phone Number</label>
                                <input v-model="form.phone" type="text" class="form-control" required>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <b-form-group class="">
                                <label>Email</label>
                                <input v-model="form.email" type="email" class="form-control">
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group class="">
                                <label>Gender</label>
                                <b-form-select v-model="form.gender" :options="gender" required></b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group class="">
                                <label>Religion</label>
                                <b-form-select v-model="form.religion" :options="religion" required></b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Staff Picture</label>
                                    <input type="file" accept="image/*" @change="uploadImage" name="image" class="form-control">
                                </div>

                                <div class="my-2" v-if="staff.image">
                                    <img :src="form.image" class="img-fluid" style="height:50px">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                <label>Current Address</label>
                                <input v-model="form.current_address" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                <label>Parmanent Address</label>
                                <input v-model="form.parmanent_address" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                <label>Country</label>
                                <input v-model="form.country" type="text" class="form-control" readonly="true">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <b-form-group class="">
                                    <label>State of Origin</label>
                                    <select v-model="form.state" class="form-control" @change="onChange($event)">
                                        <option value=null> -- Select State -- </option>
                                        <option v-for="option in states" :key="option.id" v-bind:value="option.id">
                                        {{ option.title }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group class="">
                                    <label>LGA of Origin</label>
                                    <select v-model="form.city" class="form-control">
                                    <option value=null> -- Select LGA -- </option>
                                    <option v-for="option in lgas" v-bind:value="option.id" v-bind:key="option.id">
                                        {{ option.name }}
                                    </option>
                                </select>
                                </b-form-group>
                            </div>                  
                        </b-row>
            
                        <hr>
                        <h2>Admissions</h2>
                        <b-row>
                            <div class="col-md-3">
                                <div class="form-group">
                                <label>Staff Number</label>
                                <input v-model="form.roll" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                <label>Salary</label>
                                <input v-model="form.salary" type="number" step="0.1" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-3">
                                <b-form-group class="">
                                <label>Date of Employment</label>
                                <input v-model="form.doa" type="date" class="form-control">
                                </b-form-group>
                            </div>

                            <div class="col-md-3">
                                <b-form-group class="">
                                <label>Category</label>
                                <b-form-select v-model="form.category" :options="category"></b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group class="">
                                <label>Position</label>
                                <b-form-select v-model="form.position" :options="positions"></b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                            <b-form-group class="">
                            <label>Level</label>
                            <b-form-select v-model="form.level" :options="levels"></b-form-select>
                            </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group class="">
                                <label>Subject</label>
                                <select v-model="form.subject" class="form-control">
                                    <option value=0> -- Select Subject-- </option>
                                    <option v-for="option in subjects" v-bind:value="option.id" v-bind:key="option.id">
                                    {{ option.name }}
                                    </option>
                                </select>
                                </b-form-group>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                <label>Last Qualification Obtained</label>
                                <input v-model="form.last_degree" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                <label>Last School/University Attended</label>
                                <input v-model="form.university_attended" type="text" class="form-control">
                                </div>
                            </div>
                        </b-row>
                        <hr>
            
                        <button v-show="editMode" type="submit" class="btn btn-success btn-block">Update</button>
                        <button v-show="!editMode" type="submit" class="btn btn-primary btn-block">Create</button>
                    </form>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
  import axios from 'axios';
  import moment from 'moment';
  import { Form } from 'vform';

  export default {
    created(){
        this.roleHelper(['1', '5']);
        this.loadData();
    },
    
    data(){
        return{
            editMode: false,
            gender: [
            { value: null, text: 'Select Gender' },
            { value: 'male', text: 'Male' },
            { value: 'female', text: 'Female' },
            ],

            category: [
            { value: null, text: 'Select' },
            { value: '1', text: 'Academic Staff' },
            { value: '2', text: 'Non Academic Staff' },
            ],

            positions: [
            { value: null, text: '---Select Position---' },
            { value: '1', text: 'Principal' },
            { value: '2', text: 'Vice Principal' },
            { value: '3', text: 'Bursar' },
            { value: '4', text: 'Head of Department' },
            { value: '5', text: 'Hostel Teacher' },
            { value: '6', text: 'Teacher' },
            { value: '7', text: 'Head Teacher' },
            { value: '8', text: 'Asst Head Teacher' },
            { value: '0', text: 'Others' },
            ],

            levels: [
            { value: null, text: '---Select Level---' },
            { value: '8', text: 'Level 8' },
            { value: '9', text: 'Level 9' },
            { value: '10', text: 'Level 10' },
            { value: '11', text: 'Level 11' },
            { value: '12', text: 'Level 12' },
            { value: '13', text: 'Level 13' },
            { value: '14', text: 'Level 14' },
            { value: '15', text: 'Level 15' },
            { value: '16', text: 'Level 16' },
            { value: '17', text: 'Level 17' },
            { value: '18', text: 'Level 18' },

            ],

            religion: [
            { value: null, text: 'Select Religion' },
            { value: 'Christianity', text: 'Christianity' },
            { value: 'Muslim', text: 'Muslim' },
            { value: 'African Tradition Religion', text: 'African Traditional Religion' },
            ],

            parents: {},
            subjects: {},
            classes: {},
            states: {},
            lgas: {},
            staff: '',
            form: new Form({
            id: '',
            firstname: '',
            lastname: '',
            middlename: '',
            roll: '',
            password: '',
            gender: null,
            religion: null,
            dob: '',
            parmanent_address: '',
            current_address: '',
            country: 'Nigeria',
            state: null,
            city: null,
            email: '',
            phone: '',
            password: '',
            position: null,
            category: null,
            subject: 0,
            doa: '',
            subject: 0,
            image: '',
            salary: '',
            level: null,
            last_degree: '',
            university_attended: '',
            is_busy: false,
            }),

            is_busy: false,
        }
    },

    methods: {
      loadData(){
        let staff_id = this.$route.params.staff_id;
        if (this.is_busy) return;
        this.is_busy = true;

        let url = '/loaddata';
        if(staff_id) {
            this.editMode = true;
            url = '/staffdata/' + staff_id;
        }

        axios.get(url) 
        .then(({data}) => {
          this.states = data.data.states;
          this.subjects = data.data.subjects;
          this.classes = data.data.classes;
          if(staff_id) {
            this.staff = data.data.staff;
            this.form = data.data.staff;
            this.form.class = data.data.staff_class;
            this.form.doa = moment(data.data.staff.doa).format("YYYY-MM-DD");
            this.form.dob = moment(data.data.staff.dob).format("YYYY-MM-DD");

            if(this.form.state)
            axios.get('/loadLGA/'+ this.form.state) 
            .then(({data}) => {
              this.lgas = data;
              
            })
          }
        })
        .catch(() => {
          Swal.fire(
            "Failed!",
            "Ops, Something went wrong, try again.",
            "warning"
          );
        })

        .finally(() => {
            this.is_busy = false;
        });
      },

        onChange(event) {
            let id = event.target.value;
            axios.get('loadLGA/'+id) 
            .then(({data}) => {
                this.lgas = data;
            })
        },

        uploadImage(e){
            let file = e.target.files[0];
            let reader = new FileReader();
            if(file['size'] < 2388608){
            reader.onloadend = (file) => {
                this.form.image = reader.result;
            }
            reader.readAsDataURL(file);
            }else{
            Swal("Failed!", "Oops, You are uploading a large file, try again. Upload file less that 8MB", "Warning")
            }
        },

      createStaff(){
        if(this.is_busy) return;
        this.is_busy = true;
        axios.post('/staff', this.form)
        .then(res => {
            Swal.fire(
                'Created!',
                'Staff Created Successfully.',
                'success'
            )
            //console.log(data);
            this.$router.push({path: '/administration/staff/'+ res.data.data.id });
        })

        .catch((err) => {
            this.errors = err.response.data.errors;
        })
        .finally(() => {
            this.is_busy = false;
        });      
      },

      updateStaff(){
        if(this.is_busy) return;
        this.is_busy = true;
        
        axios.put('/staff/'+this.form.id, this.form)
        .then(({data})=>{
            Swal.fire(
              'Updated!',
              'Staff Updated Successfully.',
              'success'
            )
            this.$router.push({path: '/administration/staff/'+ data.data.id });
        })

        .catch((err) => {
            this.errors = err.response.data.errors;
        })
        .finally(() => {
            this.is_busy = false;
        });      
      },
    },
  }
</script>

<style>
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: white;
    background-color: red;
    border-color: #dee2e6 #dee2e6 #f8fafc;
  }
</style>